.login-page {
  margin: 200px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form {
  margin: 20px 0;
}

.field {
  margin: 10px 0;
}

.password {
  width: 300px;
}

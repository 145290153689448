.credits-page {
  text-align: center;
  margin: 0 auto;
  padding: 50px 0;
  width: 800px;

  a {
    color: black;
    text-decoration: underline;
  }

  a:hover {
    background-color: black;
    color: white;
    text-decoration: underline;
  }
}

.credits-paragraph {
  font-size: 16px;
  text-align: center;
}

.datacult-logo-container {
  padding: 20px;
}

.datacult-logo {
  width: 38px;
  background-color: black;
}

.datacult-team {
  display: block;
  margin: 0 auto;
}

.container {
  z-index: 10;
}

.backdrop {
  top: -121.8447265625px;
  left: 0.2828369140625px;
  width: 1644px;
  height: 1200px;
  transform: matrix(0.85, -0.53, 0.53, 0.85, 0, 0);
  background: #e2e9e9 0% 0% no-repeat padding-box;
  opacity: 1;
  z-index: 0;
}

.select_container {
  margin: 50px 0;
  width: 330px;
}

.content {
  width: 500px;
  text-align: left;
}

.link {
  color: #757474;
}

a {
  color: inherit;
}

.chart_title {
  font-family: "Helvetica Neue", Helvetica, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 0;
  color: #ffffff;
}

.takeaway_paragraph {
  font-size: 14px;
  width: 500px;
  text-align: left;
  margin-top: 0;
}

@import "./colors";

.app {
  text-align: left;
  // background: $darkgrey;
}

// #landing {
//   background-image: url("images/background.png");
//   background-repeat: no-repeat;
//   background-size: 100%;
// }

.landing-heading-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.landing-column-left {
  padding-top: 150px;
  padding-bottom: 150px;
  width: 45%;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.landing-column-right {
  padding-top: 100px;
  // padding-left: 100px;
  // opacity: 0;
  width: 45%;
  -webkit-animation-duration: 5s;
  animation-duration: 5s;
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

.landing-heading {
  font-size: 56px;
  margin-bottom: 10px;
  width: 404px;
  font-family: "Helvetica Neue", Helvetica, sans-serif;
  font-style: normal;
  font-weight: bold;
  // font-size: 54.5946px;
  line-height: 67px;
  text-transform: uppercase;
  color: #ffffff;
}

.landing-sub-heading {
  width: 549px;
  font-family: "Helvetica Neue", Helvetica, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 34.3554px;
  line-height: 42px;
  text-transform: uppercase;

  color: #757474;
}

.user-guide-container {
  display: flex;
  justify-content: center;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  transform-origin: bottom;
  animation-name: bounce;
  animation-timing-function: ease;
}

.down-arrow {
  width: 34px;
  height: 34px;
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  30% {
    transform: translateY(-10px);
  }
  50% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(0);
  }
}

#overview {
  display: flex;
  flex-direction: column;
  padding-top: 150px;
  text-align: left;
  z-index: 100;
  // background: $darkgrey;
}

// #culture, #performance, #operations {
//   background: $darkgrey;
// }

.overview-heading-container {
  max-width: 500px;
  padding-bottom: 50px;
}

.overview-paragraph {
  // font-size: 12px;
  text-align: left;
  width: 350px;
}

#culture {
  padding-top: 100px;
}

.culture-heading {
  margin-bottom: 20px;
}

.heading__plus {
  color: $light-blue;
}

.culture-paragraph {
  max-width: 400px;
  color: black;
}

.culture-content {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
}

.culture-content-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 380px;
  max-width: 400px;
  margin-right: 20px;
  margin-bottom: 20px;
}

.culture-content-right {
  width: 90%;
}

.headcount-content {
  width: 90%;
}

.content-title__logo {
  width: 60px;
  border: 5px solid $grey10;
  opacity: 1;
  transform: rotate(-45deg);
  position: absolute;
  z-index: -1;
  background-color: $grey10;
  margin-top: 10px;
  margin-left: -35px;
}

.content-title {
  font-size: 30px;
  font-weight: bold;
  color: $white;
  font-family: "Helvetica Neue", Helvetica, sans-serif;
}

.culture-content-paragraph {
  text-align: left;
  margin: 0;
}

.diversity-inclusion-paragraph-container {
  display: flex;
  flex-direction: column;
  width: 1000px;
  margin-top: 10px;
}

.diversity-inclusion-paragraph {
  max-width: 500px;
  text-align: left;
  font-size: 14px;
  font-family: "Helvetica Neue", Helvetica, sans-serif;
}

.diversity-inclusion-notes {
  max-width: 500px;
  text-align: left;
  font-size: 14px;
  margin: 5px 0px;
  font-family: "Helvetica Neue", Helvetica, sans-serif;
}

#operations {
  padding-top: 100px;
  display: flex;
  flex-direction: column;
}

.hr-ops-team {
  margin-bottom: 100px;
}

.ops-team-ratios {
  margin-top: 30px;
  width: 490px;
}

.ops-team-paragraph__left {
  font-size: 14px;
  text-align: left;
  width: 500px;
}

.hr-ops-team-paragraph-container {
  display: flex;
}

.hr-ops-team-paragraph {
  font-size: 14px;
  width: 500px;
  text-align: left;
}

.operations-content {
  margin-bottom: 50px;
}

#performance,
#workplace-experience,
#budget-headcount {
  padding-top: 100px;
}

li {
  color: white;
}

#takeaways {
  display: flex;
  flex-direction: column;
  padding-top: 200px;
  text-align: left;
  z-index: 100;
  background: #e2e9e9;
}

.chart-name {
  width: 400px;
  font-weight: bold;
  font-size: 14px;
  color: white;
  padding-bottom: 5px;
  // border-bottom: 2px solid #a9c4c9;
  margin-bottom: 20px;
}

.chart-name-list,
.chart-name-list-tech,
.chart-name-list-right {
  font-weight: bold;
  font-size: 14px;
  color: white;
  padding-bottom: 5px;
  border-bottom: 1px solid #4f4f4f;
  margin-bottom: 20px;
}

.chart-name-list {
  width: 100%;
}

.chart-name-list-right {
  width: 100%;
}

.chart-name-list-tech {
  width: 250px;
}

.dei_hover_1 {
  position: relative;
  display: inline-block;
  text-align: left;
  font-size: 14px;
  // margin: 5px 0px;
  margin: 0;
}

.hover_text {
  position: relative;
  display: inline-block;
  text-align: left;
  // font-size: 12px;
  margin: 5px 0px;
  border-bottom: 1px dotted white;
}

.hover_text .tooltip {
  visibility: hidden;
  width: 180px;
  background-color: black;
  color: #fff;
  text-align: left;
  padding: 5px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: normal;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  top: -25px;
  left: 105%;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.hover_text:hover .tooltip {
  visibility: visible;
}

.hover_text1 {
  position: relative;
  display: inline-block;
  text-align: left;
  // font-size: 12px;
  // margin: 5px 0px;
  border-bottom: 1px dotted white;
}

.hover_text1 .tooltip {
  visibility: hidden;
  width: 160px;
  background-color: black;
  color: #fff;
  text-align: left;
  padding: 5px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: normal;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  top: -25px;
  left: 105%;
  z-index: 1;
  // bottom: 100%;
  // left: 50%;
  // margin-left: -60px;
}

/* Show the tooltip text when you mouse over the tooltip container */
.hover_text1:hover .tooltip {
  visibility: visible;
}

.thrive-footer-logo {
  height: 50px;
}

.footer-logo-text {
  font-family: "Helvetica Neue";
  font-size: 45px;
  color: #ffffff;
}

.footer-text {
  font-family: "Helvetica Neue";
  font-size: 16px;
  color: #ffffff;
}

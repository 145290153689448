@import "./../colors";

.container {
  width: 100%;
}

.row {
  border-bottom: 0.5px solid #4f4f4f;
  display: flex;
  flex-direction: row;
  width: 100%;
}

.text_row {
  justify-content: left;
  display: flex;
  flex-direction: row;
  width: 100%;
}

.left {
  font-size: 12px;
  color: $white;
  width: 25%;
  border-right: 0.5px solid #4f4f4f;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.left_donut {
  font-size: 12px;
  color: $white;
  width: 30%;
  border-right: 0.5px solid #4f4f4f;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.donut_label {
  width: 50%;
  margin: 0;
}

.donut_box {
  width: 50%;
  margin: 0;
}

.left__header_row {
  font-size: 13px;
  width: 200px;
  display: flex;
  flex-direction: column;
  margin: 10px 0;
}

.right {
  width: 100%;
}

.third {
  width: 20px;
}

.right__header_row {
  color: #afafaf;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 10px 0;
  text-align: left;
  font-size: 10px;
  width: 100%;
}

.title,
.title_donut {
  font-family: "Helvetica Neue", Helvetica, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #ababab;
  // padding-left: 5%;
  display: flex;
  align-items: flex-end;
}

.title {
  padding-left: 5%;
}

.title_donut {
  padding-left: 9%;
}

.chart_title,
.text_title {
  font-family: "Helvetica Neue", Helvetica, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
}

.text_title {
  margin: 0;
}

.text_body {
  margin: 0;
}

.text_left,
.text_right {
  width: 450px;
}

.text_left {
  margin-right: 10%;
}

.content_title {
  font-size: 30px;
  font-weight: bold;
  color: $white;
}

.title__sm {
  font-family: "Helvetica Neue", Helvetica, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #ababab;
  display: flex;
  align-items: flex-end;
}

.text {
  text-align: left;
  font-size: 13px;
  color: #263f5a;
}

.legend_group {
  margin-left: 10%;
  display: flex;
  flex-direction: row;
}

.legend_container {
  min-height: 20px;
  display: flex;
  margin-right: 10px;
}

.legend_text {
  text-align: left;
  font-size: 11px;
  color: white;
}

.legend_box_re1 {
  background-color: #e6e6e6;
}

.legend_box_re2 {
  background-color: #a9c5eb;
}

.legend_box_re3 {
  background-color: #6a87d1;
}

.legend_box_light,
.legend_box_dark,
.legend_box_2,
.legend_box_3,
.legend_box_4,
.legend_box_re1,
.legend_box_re2,
.legend_box_re3 {
  width: 19px;
  height: 19px;
  margin-right: 5px;
}

.legend_box_light {
  background-color: #e0e3e7;
}

.legend_box_dark {
  background-color: #4e66a5;
}

.legend_box_2 {
  background-color: #91afd8;
}

.legend_box_3 {
  background-color: #435b9b;
}

.legend_box_4 {
  background-color: #16213d;
}

.axis {
  display: flex;
  flex-direction: row;
  width: 80%;
  padding-left: 20%;
  justify-content: space-between;
}

.axis_donut {
  display: flex;
  flex-direction: row;
  width: 77%;
  padding-left: 23%;
  justify-content: space-between;
}

.axis_item {
  text-align: left;
  font-size: 14px;
  color: #ababab;
}

.pie_description {
  margin: 5px 0;
  font-size: 13px;
  color: #263f5a;
}

.donut {
  width: 50px;
  margin: 0 10px;
}

.bar_container {
  padding: 30px 0;
}

.bar_container_double {
  padding: 0px 0;
}

.table {
  margin-top: 20px;
  width: 660px;
}

.header_row {
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #4f4f4f;
  // color: $dark-blue;
  // font-weight: bold;
}

.header_row > .column {
  font-weight: normal;
  color: #ababab;
}

.header_row > .column2,
.header_row > .column3,
.header_row > .column4 {
  font-weight: bold;
}

.table_row > .column2,
.table_row > .column3,
.table_row > .column4 {
  border-left: 1px solid #4f4f4f;
}

.table_row {
  display: flex;
  justify-content: space-between;
  color: black;
  // font-size: 16px;
  // margin: 5px 0;
  // padding: 5px 0;
  border-bottom: 1px solid #4f4f4f;
}

.column,
.column2,
.column3,
.column4 {
  width: 250px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 15px;
  // text-align: left;
}

.column2,
.column3,
.column4 {
  padding-left: 15px;
}

.column {
  color: white;
  text-align: left;
}

.column2 {
  color: #6a87d1;
  text-align: center;
}

.column3 {
  color: #8bb1e4;
  text-align: center;
}

.column4 {
  color: #d4d4d4;
  text-align: center;
}

.tool {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted white; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tool .tooltip {
  visibility: hidden;
  width: 200px;
  font-size: 11px;
  background-color: black;
  color: #fff;
  text-align: left;
  padding: 5px;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

.tool .tooltip {
  top: -5px;
  left: 105%;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tool:hover .tooltip {
  visibility: visible;
}

.toolbox {
  position: relative;
  // display: inline-block;
  // border-bottom: 1px dotted salmon; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.toolbox .tooltip,
.toolbox .tooltip2,
.toolbox .tooltip3 {
  visibility: hidden;
  width: 80px;
  background-color: black;
  color: #fff;
  text-align: left;
  font-size: 11px;
  padding: 5px;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

.toolbox .tooltip {
  top: 80px;
  // right: ;
}

.toolbox .tooltip2 {
  top: 10px;
  // left: 300px;
}

.toolbox .tooltip3 {
  top: 10px;
  // left: 10px;
}

/* Show the tooltip text when you mouse over the tooltip container */
.toolbox:hover .tooltip,
.toolbox:hover .tooltip2,
.toolbox:hover .tooltip3 {
  visibility: visible;
}

.placeholder {
  min-height: 400px;
}

$red: #b51918;

$light-blue: #a9c4c9;
$dark-blue: #263f5a;

$white: #ffffff;
$grey10: #e2e9e9;
$grey20: #e1e1e1;
$grey30: #b4b4b4;
$grey100: #909090;
$black: #000000;

$grey: rgba(98, 98, 98, 0.19);
$darkgrey: linear-gradient(120.98deg, #232323 12.26%, #343434 97.28%);

    

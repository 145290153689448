@import "./../colors";

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 55px;
  top: 0;
  position: sticky;
  background-color: $grey;
  z-index: 1000;
  padding-left: 10px;
  width: 100vw;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(1000px);
}

.nav-links {
  width: 60vw;
  text-align: start;
  padding: 15px 0;
}

.nav-link {
  color: $white;
  font-size: 14px;
  margin: 0 10px;
  transition: 0.1s ease-in-out;
  transition-property: color, background-color;
}

.nav-link__download {
  width: 100px;
  height: 20px;
  line-height: 20px;
  display: inline-block;
  background-color: #ffffff;
  border: 0.5px solid #727272;
  color: black;
  font-weight: 300;
  font-size: 11px;
  padding: 5px 10px;
  text-align: center;
  margin: 0 10px;
  transition: 0.1s ease-in-out;
  transition-property: border, background-color;
}

.nav-link__download:hover {
  border: 0.5px solid black;
  background-color: $grey10;
}

.thrive-logo {
  margin-right: 10px;
  // width: 50px;
  height: 20px;
}

.logo {
  font-size: 18px;
  font-weight: 500;
  color: white;
  margin-right: 25px;
}
